.shutdown-screen {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 5001;

    background-color: black;
}

.shutdown-screen-text {
    color: white;
    font-family: 'VT323', monospace;
    font-size: 30px;
}

.shutdown-screen-spacer {
    height: 40%;
}