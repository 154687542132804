.menu-bar {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0px;
  padding-top: 2px;
  background-color: #bbbbbb;
  border-top-style: solid;
  border-top-color: #FFFFFF;
  border-top-width: 2px;
  z-index: 5000 !important;
}

.menu-button {
  font-family: 'w95fa';
  float: left;
  height: 30px;
  left: 5px;
  color: black;
  border-style: solid;
  border-width:2px;
  font-weight: bold;
  margin-left: 5px;
  margin-bottom: 2px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.menu-button-inactive {
  border-color:#FFFFFF #000000 #000000 #FFFFFF;
  background-color: #C0C0C0;
}

.menu-button-active {
  border-color:#000000 #FFFFFF #FFFFFF #000000;
  background-color: #EBECF0;
}

.menu-bar-menu {
  height: 50px;
  width: 300px;
  background-color: #bbbbbb;
  position: absolute;
  bottom: 40px;
  border-top-style: solid;
  border-right-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-color: black;
  overflow: hidden;
}

.menu-bar-menu-item {
  font-family: 'w95fa';
  position: relative;
  border-top: 0px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 0px;
  border-color: black;
  border-style: solid;
  height: 50px;
}

.menu-bar-menu-item-active {
  background-color: white;
}

.icon-row {
  position: absolute;
  left: 5px;
  bottom: 2px;
}

.menu-bar-datetime {
  position: absolute;
  right: 2px;
  bottom: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: #808080 #FFFFFF #FFFFFF #808080;
  background-color: #bbbbbb;
  height: 30px;
  margin-bottom: 2px;
}

.menu-bar-font {
  font-size: 20px;
  font-family: 'w95fa';
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.menu-bar-item-box {
  border-style: solid;
  border-width: 2px;
  margin-bottom: 2px;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

.menu-bar-item-image {
  margin-bottom: 2px;
  float: left;
  margin-right: 5px;
  margin-top: 2px;
}

.menu-bar-item-text {
  font-family: 'w95fa';
  color: black;
  font-size: 15px;
  float: left;
  display: inline;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@media only screen and (max-device-width: 480px) {
  .menu-bar-item-text {
    color: black;
    font-size: 0px;
    float: left;
    display: inline;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  .menu-bar-item-image {
    margin-bottom: 2px;
    float: left;
    margin-right: 0px;
    margin-top: 2px;
  }
}