.test-component-main {
  display: flex;
  border-style: solid;
  background-color: white;
  border-width: 0px;
  position: absolute;
  overflow: hidden;
}

.header-bar {
  border-bottom: solid;
  height: 30px;
  background-color: #000080;
  border-width: 1px;
  width: 100%;
  padding-right: 4px;
  padding-left: 5px;
}

.font-header-bar {
  font-family: 'VT323', monospace;
  font-size: 20px;
  color: white;
}

.window-button {
  height: 20px;
  width: 20px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  color: black;

  /* width:14px;
  height:13.5px; */
  background:#C0C0C0;
  border-width:1px;
  border-color:#FFFFFF #808080 #808080 #FFFFFF;
  padding:0;
  font-size:9px;
  font-weight:bold;
  font-family:Tahoma;
  text-align:center;
}

.bottom-bar {
  position: absolute;
  bottom: 0px;
}

.window-component-bottom-right-corner {
  border-bottom-color: #FFFFFF;
  border-right-color: #808080;
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: nwse-resize;
  height: 10px;
  width: 10px;
  z-index: 101;
}

.window-component-contents-border {
  background-color: #bbbbbb;
  height: 100%;
}

.window-component-contents-inner-border {
  margin-right: 0px;
  margin-left: 0px;
  background-color: #bbbbbb;
  height: 100%;
  border: solid;
  border-color:#000000 #FFFFFF #FFFFFF #000000;
  border-width: 2px;
  overflow-y: scroll;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.window-component-contents-inner-border::-webkit-scrollbar {
  display: none;
}