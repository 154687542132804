.load-screen {
    z-index: 10001;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: blue;
    color: white;
    font-family: 'VT323', monospace;
    font-size: 30px;
}

.ityped-cursor {
    font-size: 2.2rem;
    opacity: 1;
    -webkit-animation: blink 0.3s infinite;
    -moz-animation: blink 0.3s infinite;
    animation: blink 0.3s infinite;
    animation-direction: alternate;
}

.animated-text {
    display: inline;
}
 
@keyframes blink {
    100% {
        opacity: 0;
    }
}
 
@-webkit-keyframes blink {
    100% {
        opacity: 0;
    }
}
 
@-moz-keyframes blink {
    100% {
        opacity: 0;
    }
}