.os-screen {
    z-index: 10000;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: blue;
    color: #fe019a;
    font-family: 'VT323', monospace;
}

.os-screen-text {

    font-size: 30vw;
    margin-top: 0px;
}

.os-screen-text-space {
    font-size: 2vw;
    margin-top: 50px;
}

.os-screen-text-box {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.os-screen-bottom-text-box {
    position: absolute;
    bottom: 0px;
    width: 95%;
}

.os-screen-bottom-text {
    color: white;
    font-size: 3vw;
}