.documents-row-active {
    background-color: blue;
    color: white;
    border-top: solid;
    border-bottom: solid;
    border-color: black;
    border-width: 1px;
}

.documents-row-inactive {
    background-color: white;
    color: black;
    border-top: solid;
    border-bottom: solid;
    border-color: black;
    border-width: 1px;
}

.documents-table-heading {
    background-color: #bbbbbb;
    border: solid;
    border-width: 2px;
    border-color: black;
    border-color:#FFFFFF #000000 #000000 #FFFFFF;
}

.documents-table-row {
    border-top: solid;
    border-bottom: solid;
    border-color: black;
    border-width: 1px;
}

.documents-text {
    font-family: "w95fa";
}