.background-body {
  position: absolute;
  overflow: hidden;
  background-color: #008080;
  height: calc(100% - 40px);
  width: 100%;
}

.App {
  overflow: hidden;
  height: 100%;
}

.underline {
  border-style: solid;
  border-color: white;
  height: 0px;
  width: 50%;
  border-width: 1px;
}

.background-body-circle {
  border-radius: 50%;
  background-color: #405e8c;
  position: absolute;
  top: -25%;
  left: -5%;
  height: 150%;
  width: 110%;
}

.container-main {
  position: absolute;
  top: calc(1vh + 35%);
  left: 0px;
  width: 100%;
}

.font-main {
  font-family: "Press Start 2P", cursive;
  font-size: 60px;
}

.font-global {
  font-family: "w95fa";
  font-size: 20px;
}

.font-secondary {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
}

.font-white {
  color: white;
}

.no-list-style {
  list-style: none;
}

.border-true {
  border-style: solid;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlightedNavBarItem {
  text-shadow: 0px 2px 20px #fff; /* inner white */
}

@font-face {
  font-family: "W95FA";
  src: local("W95FA"), url(w95fa.woff) format("woff");
}
