.icon-text {
  font-size: 20px;
  font-family: 'VT323', monospace;
  color: white;
  font-weight:lighter;
}

.desktop-file {
  touch-action: none;
  user-select: none;
}